<template>
  <div>
    <!-- /.modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <form @submit.prevent="save" class="card">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h5 class="modal-title" id="exampleModalLabel">
                <i :class="form.id ? 'fa fa-pen' : 'fa fa-plus'"></i>
                {{ form.id ? "Ubah Produk" : "Tambah Produk" }}
              </h5>
            </div>
            <div class="modal-body">
              <div class="row" style="margin-left: 10px; margin-right: 10px">
                <div class="col-sm-6">
                  <div class="row">
                    <div
                      class="form-group"
                      id="div_image"
                      @click="defaultBtnImage()"
                      style="cursor: pointer"
                    >
                      <img
                        :src="form.image"
                        v-if="form.image"
                        width="100%"
                        height="100%"
                        class="img-thumbnail"
                      />
                      <img
                        src="@assets/no-image.png"
                        v-else
                        width="100%"
                        height="100%"
                        class="img-thumbnail"
                        alt="No Image"
                      />
                      <input
                        type="file"
                        accept="image/x-png,image/jpeg"
                        id="default-btn"
                        @change="handleImage"
                        :disabled="loading || isDetail"
                        hidden
                      />
                      <div class="bg-primary text-center p-2">
                        {{ form.id ? "Ubah Foto" : "Unggah Foto"
                        }}<span class="text-red">*</span>
                      </div>
                    </div>
                    <span style="color: #757575; margin-left: 10%"
                      >Maks. 1MB, Format .jpg/.png</span
                    >
                    <span
                      v-if="!!errors.image"
                      class="text-red"
                      style="margin-left: 10%"
                      >{{ errors.image }}</span
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="message-text" class="col-form-label"
                      >Part Number<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-sku"
                      v-model="form.sku"
                      :disabled="loading || isDetail"
                      autocomplete="off"
                      @blur="validate('sku')"
                      @keypress="validate('sku')"
                    />
                    <span v-if="!!errors.sku" class="text-red">{{
                      errors.sku
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label for="recipient-name" class="col-form-label"
                      >Nama Produk<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-name"
                      v-model="form.name"
                      :disabled="loading || isDetail"
                      autocomplete="off"
                      @blur="validate('name')"
                      @keypress="validate('name')"
                    />
                    <span v-if="!!errors.name" class="text-red">{{
                      errors.name
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label for="recipient-sap" class="col-form-label"
                      >SAP ID<span class="text-red">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="recipient-sap"
                      v-model="form.sap_id"
                      :disabled="loading || isDetail"
                      autocomplete="off"
                      @blur="validate('sap_id')"
                      @keypress="validate('sap_id')"
                    />
                    <span v-if="errors.sap_id" class="text-red">{{
                      errors.sap_id
                    }}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="message-text" class="col-form-label"
                    >Deskripsi</label
                  >
                  <textarea
                    class="form-control"
                    id="recipient-description"
                    v-model="form.description"
                    :disabled="loading || isDetail"
                    maxlength="255"
                    cols="42"
                    placeholder="Ketik deskripsi di sini.."
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Batal
              </button>
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{
                  loading && form.id
                    ? "Simpan ..."
                    : form.id
                    ? "Simpan"
                    : loading
                    ? "Tambah ..."
                    : "Tambah"
                }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6" style="padding-left: 26px !important">
            <h1 class="m-0 text-dark" style="font-size: 2em">Produk</h1>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="ml-3">
                <button
                  type="button"
                  class="btn btn-block btn-primary btn-lg"
                  @click="showModal"
                >
                  <span class="fas fa-plus mr-2"></span>Tambah Produk
                </button>
              </li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <div v-if="dataProduk?.length > 0">
      <data-tables-master-data
        :config="config"
        class="container-fluid"
        id="sampleTable"
      ></data-tables-master-data>
    </div>
    <div v-else class="image-empty">
      <img src="../../assets/img/emptyProduct.png" alt="empty-product" />
    </div>
    <ModalConfirmation ref="refModalConfirmation" />
  </div>
</template>

<script>
const { console } = window;
import DataTablesMasterData from "@components/DataTablesMasterData.vue";
import ModalConfirmation from "@components/modal/Confirmation.vue";
import { string, object } from "yup";

const productFormSchema = object().shape({
  name: string().required("Nama Produk wajib diisi."),
  sku: string().required("Part Number wajib diisi."),
  sap_id: string().required("SAP ID wajib diisi."),
  image: string().required("Foto Produk harus diunggah."),
});

export default {
  components: {
    DataTablesMasterData,
    ModalConfirmation,
  },

  data() {
    return {
      active: false,
      errorDialog: null,
      uploadFieldName: "file",
      errors: {
        image: "",
        name: "",
        sku: "",
        sap_id: "",
      },
      loading: false,
      text_validation: "",
      maxSize: 1024,
      filter: [],
      form: {
        id: null,
        image: "",
        name: "",
        sku: "",
        description: "",
        sap_id: "",
      },
      // searchTerm: "",
      disabled: false,
      isDetail: false,
      dataProduk: null,
      config: {
        columns: [
          {
            title: "id",
            data: "id",
            visible: false,
            searchable: false,
          },
          {
            title: "Gambar",
            data: "image",
            sortable: false,
            orderable: false,
            className: "image-table",
            searchable: false,
            render: (h) => {
              return (
                '<img style="margin-top: -10px; margin-bottom: -10px" src="' +
                h +
                '" width="46px" height="48px" align="center">'
              );
            },
          },
          {
            title: "Part Number",
            data: "sku",
          },
          {
            title: "Nama Barang",
            data: "name",
          },
          {
            title: "SAP",
            data: "sap_id",
          },
          {
            title: "Aksi",
            data: "id",
            searchable: false,
            render: (val, _, row) => {
              return `
                <div> 
                    <a 
                        href="javascript:void" 
                        data-id="${val}" 
                        class="editor-edit" 
                        data-action="edit"
                        style="color:#9E9E9E;margin-right:10px;"
                    >
                        <i class="fa fa-edit"></i>
                    </a>
                </div>`;
            },
          },
        ],
        drawCallback: (settings) => {
          //for click edit
          $(".editor-edit").on("click", (e) => {
            this.getEdit($(e.target).closest("a").data("id"));
          });
          // $(".delete").on("click", (e) => {
          //   this.onDelete($(e.target).closest("a").data("row"));
          // });
        },
        url: this.Api.base + "/product",
        order: [[0, "desc"]],
        filter: [],
      },
    };
  },
  mounted() {
    this.getDataProduk();
    $("image-table").removeClass("sorting_desc");
  },
  props: {
    value: Object,
  },
  methods: {
    add() {},
    onClick(row) {},
    validate(field) {
      productFormSchema
        .validateAt(field, this.form)
        .then((res) => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[field] = err.message;
        });
    },
    processDelete(id) {
      this.Api.delete(`/product/${id}`).then((res) => {
        if (res.data.success) {
          this.refresh();
          this.$toast.success("Data berhasil dihapus.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      });
    },
    onDelete(row) {
      this.$refs.refModalConfirmation
        .show({
          title: "Hapus Informasi Produk?",
          subTitle: `Apakah anda yakin untuk menhapus produk ‘${row.name}’?`,
          type: "warning",
        })
        .then((isConfirm) => {
          if (isConfirm) {
            this.processDelete(row.id);
          }
        });
    },
    getEdit(id) {
      if (id) {
        this.Api.get(`/product/${id}`)
          .then((res) => {
            if (res.data) {
              $("#exampleModal").modal("show");
              this.form.id = res.data.id;
              this.form.name = res.data.name;
              this.form.sku = res.data.sku;
              this.form.image = res.data.image;
              this.form.description = res.data.description;
              this.form.sap_id = res.data.sap_id;
              this.isDetail = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    showModal() {
      $('#default-btn').val('')
      this.form.id = null;
      this.form.name = "";
      this.form.sku = "";
      this.form.image = "";
      this.form.description = "";
      this.form.sap_id = "";
      $("#exampleModal").modal("show");
      this.loading = false;
      this.errors = {};
    },
    defaultBtnImage() {
      const defaultbtn = document.querySelector("#default-btn");
      defaultbtn.click();
    },
    handleImage(e) {
      const selectedImage = e.target.files[0];
      if (
        selectedImage.type === "image/png" ||
        selectedImage.type === "image/jpeg"
      ) {
        if (selectedImage.size <= 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.form.image = e.target.result;
            this.validate('image')
          };
          reader.readAsDataURL(selectedImage);
        } else {
          alert("Foto terlalu besar (> 1MB)");
        }
      } else {
        alert("Foto tidak sesuai format");
      }
    },
    async processSave() {
      try {
        if (this.form.id != null) {
          await this.Api.put(`/product/${this.form.id}`, this.form);
          this.$toast.success("Data berhasil diubah.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          await this.Api.post("/product", this.form);
          this.$toast.success("Data berhasil ditambah.", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }

        this.loading = false;
        this.refresh();
        $("#exampleModal").modal("hide");
      } catch (error) {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;
      productFormSchema
        .validate(this.form, { abortEarly: false })
        .then(() => {
          this.error = {};
          this.processSave();
        })
        .catch((err) => {
          this.loading = false;
          err.inner.forEach((error) => {
            this.errors[error.path] = error.message;
          });
        });
    },
    refresh() {
      this.config = {
        url: this.Api.base + "/product",
      };
    },
    async getDataProduk() {
      const res = await Api.get("/product");
      this.dataProduk = res.data.content;
    },
    formatRupiah(angka, prefix) {
      var number_string = angka.replace(/[^,\d]/g, "").toString(),
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        let separator;
        separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
    },
    formatPrice(value) {
      let val = (value + "")
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return val;
    },
    changePrice(e) {
      return (this.form.price = this.formatRupiah(e.target.value));
    },
    removeSeparator(value) {
      value = value.toString().split(".").join("");
      return parseInt(value);
    },
  },
};
</script>

<style>
.image-table .sorting_desc {
  display: none;
}
</style>
